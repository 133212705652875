// ============================================================
// ATTENTION & COMMON RULE!!
// 関数を実装のみ（処理の実行は下部で実行する）
// 関数名には振る舞いを表す英語プラスFuncを付ける
// ============================================================

// レスポンシブ判定 & デバイス判定関数（PC,SP共通処理）
// -------------------------------------------------------------

function mq(device) {
  if (window.matchMedia('(max-width:768px)').matches && device === 'sp') {
    return true;
  } else if (
    window.matchMedia('(min-width:769px)').matches &&
    device === 'pc'
  ) {
    return true;
  }
  return false;
}
// FUNCTIONS

function lazyLoadVideoSp() {
  var swiper = new Swiper('#sliderWrapFirst', {
    effect: 'fade',
    pagination: {
      el: '.swiper-pagination'
    },
    speed: 3000,
    autoplay: {
      delay: 3000
    },
    loop: true
  });

  document.getElementById("headerWrap").classList.add('show');
 
}


function topFunc() {
    lazyLoadVideoSp();
  

  var firstText = document.getElementById('firstText');
  var secoundText = document.getElementById('secoundText');
  // var sliderWrap = document.getElementById('sliderWrap');
  var headerWrap = document.getElementById('headerWrap');

  // sliderWrap.classList.add('show');
  firstText.classList.add('show');
  secoundText.classList.add('show');

  setTimeout(function(){
    headerWrap.classList.add('show');
  }, 1200);
 
 
}

function subFunc() {
  var headerWrap = document.getElementById('headerWrap');
  headerWrap.classList.add('show');
  if ($('#sekouGallery').length) {
    $('#sekouGallery').magnificPopup({
      delegate: 'a', // child items selector, by clicking on it popup will open
      type: 'image',
      titleSrc: 'title',
      gallery: { enabled: true }
      // other options
    });
  }
}

function formCheck(){
  //エラーを表示する関数（error クラスの p 要素を追加して表示）
  function show_error(message, this$) {
    text = this$.parent().find('label').text() + message;
    this$.parent().append("<p class='error'>" + text + "</p>")
  }
  
  //フォームが送信される際のイベントハンドラの設定
  $("#formPage1").submit(function(){
    //エラー表示の初期化
    $("p.error").remove();
    $("div").removeClass("error");
    var text = "";
    $("#errorDispaly").remove();
    
    //メールアドレスの検証
    var email =  $.trim($("#email").val());
    if(email && !(/^([a-z0-9\+_\-]+)(\.[a-z0-9\+_\-]+)*@([a-z0-9\-]+\.)+[a-z]{2,6}$/gi).test(email)){
      $("#email").after("<p class='error'>メールアドレスの形式が異なります</p>")
    }
    //確認用メールアドレスの検証
    // var email_check =  $.trim($("#email_check").val());
    // if(email_check && email_check != $.trim($("input[name="+$("#email_check").attr("name").replace(/^(.+)_check$/, "$1")+"]").val())){
    //   show_error("が異なります", $("#email_check"));
    // }
    //電話番号の検証
    var tel = $.trim($("#tel").val());
    if(tel && !(/^\(?\d{2,5}\)?[-(\.\s]{0,2}\d{1,4}[-)\.\s]{0,2}\d{3,4}$/gi).test(tel)){
      $("#tel").after("<p class='error'>電話番号の形式が異なります（半角英数字でご入力ください）</p>")
    }

     //カナの検証
     var kana = $.trim($("#kana").val());
     if(kana && !kana.match(/^[ァ-ヶー　]+$/)){
       $("#kana").after("<p class='error'>全角カタカナではない文字が含まれています</p>")
     }
    
    //1行テキスト入力フォームとテキストエリアの検証
    $(":text,textarea").filter(".validate").each(function(){
      //必須項目の検証
      $(this).filter(".required").each(function(){
        if($(this).val()==""){
          show_error("必須項目です", $(this));
        }
      })  
      //文字数の検証
      $(this).filter(".max30").each(function(){
        if($(this).val().length > 30){
          show_error(" は30文字以内です", $(this));
        }
      })
      $(this).filter(".max50").each(function(){
        if($(this).val().length > 50){
          show_error(" は50文字以内です", $(this));
        }
      })
      $(this).filter(".max100").each(function(){
        if($(this).val().length > 100){
          show_error(" は100文字以内です", $(this));
        }
      })
      $(this).filter(".max1000").each(function(){
        if($(this).val().length > 1000){
          show_error(" は1000文字以内でお願いします", $(this));
        }
      }) 
    })
 
    //error クラスの追加の処理
    if($("p.error").length > 0){
      $("p.error").parent().addClass("error");
      $('html,body').animate({ scrollTop: $("p.error:first").offset().top-180 }, 'slow');
      return false;
    }
  })

  
  //テキストエリアに入力された文字数を表示
  // $("textarea").on('keydown keyup change', function() {
  //   var count = $(this).val().length;
  //   $("#count").text(count);
  //   if(count > 1000) {
  //     $("#count").css({color: 'red', fontWeight: 'bold'});
  //   }else{
  //     $("#count").css({color: '#333', fontWeight: 'normal'});
  //   }
  // });

}
function questionnaireCheck(){

 //エラーを表示する関数（error クラスの p 要素を追加して表示）
  function show_error(message, this$) {
    text = message;
    this$.parents().closest('.input-wrap').append("<p class='error'>" + text + "</p>")
  }

  $("#formPage2").submit(function(){
    // alert('EEEEEEE');

    //エラー表示の初期化
    $("p.error").remove();
    $("div").removeClass("error");
    var text = "";
    $("#errorDispaly").remove();

    $(this).filter(".required").each(function(){
      if($(this).val()==""){
        show_error(" は必須項目です", $(this));
      }
    })  

    var ancFlg01 = false;
    $(".a01").each(function(){
      console.log($(this).prop('checked'));
      if($(this).prop('checked') === true){
        ancFlg01 = true;
      }
    });
    if(!ancFlg01){
      show_error(" 必須項目です", $('.a01'));
    }

    var ancFlgseibetsu = false;
    $(".seibetsu").each(function(){
      console.log($(this).prop('selected'));
      if($(this).prop('selected') === true){
        ancFlgseibetsu = true;
      }
    });
    if(!ancFlgseibetsu){
      show_error(" 必須項目です", $('.seibetsu'));
    }
    var ancFlgnenrei = false;
    $(".nenrei").each(function(){
      console.log($(this).prop('selected'));
      if($(this).prop('selected') === true){
        ancFlgnenrei = true;
      }
    });
    if(!ancFlgnenrei){
      show_error(" 必須項目です", $('.nenrei'));
    }


    var ancFlgsyokugyou = false;
    $(".syokugyou").each(function(){
      console.log($(this).prop('selected'));
      if($(this).prop('selected') === true){
        ancFlgsyokugyou = true;
      }
    });
    if(!ancFlgsyokugyou){
      show_error(" 必須項目です", $('.syokugyou'));
    }
    
    var ancFlgprefacture = false;
    $(".prefacture").each(function(){
      console.log($(this).prop('selected'));
      if($(this).prop('selected') === true){
        ancFlgprefacture = true;
      }
    });
    if(!ancFlgprefacture){
      show_error(" 必須項目です", $('.prefacture'));
    }
    var ancFlgkikkake = false;
    $(".kikkake").each(function(){
      console.log($(this).prop('checked'));
      if($(this).prop('checked') === true){
        ancFlgkikkake = true;
      }
    });
    if(!ancFlgkikkake){
      show_error(" 必須項目です", $('.kikkake'));
    }
    var ancFlgpoint = false;
    $(".point").each(function(){
      console.log($(this).prop('checked'));
      if($(this).prop('checked') === true){
        ancFlgpoint = true;
      }
    });
    if(!ancFlgpoint){
      show_error(" 必須項目です", $('.point'));
    }

    var ancFlgmodel = false;
    $(".model").each(function(){
      console.log($(this).prop('checked'));
      if($(this).prop('checked') === true){
        ancFlgmodel = true;
      }
    });
    if(!ancFlgmodel){
      show_error(" 必須項目です", $('.model'));
    }

    var ancFlgkansinn = false;
    $(".kansinn").each(function(){
      console.log($(this).prop('checked'));
      if($(this).prop('checked') === true){
        ancFlgkansinn = true;
      }
    });
    if(!ancFlgkansinn){
      show_error(" 必須項目です", $('.kansinn'));
    }

    // return false;
    
    // //error クラスの追加の処理
    if($("p.error").length > 0){
      // $("p.error").parent().addClass("error");
      $('html,body').animate({ scrollTop: $("p.error:first").offset().top-180 }, 'slow');
      return false;
    }

  });
}
// ============================================================
// ATTENTION & COMMON RULE!!
// まとめて関数実行（※必要に応じて条件分岐を入れる）
// ページ個別に処理をする場合は「ページ固有のID名.lengthで処理を分岐」
// ============================================================

// --- ページの全データを読み込み後 ----------------------------------
$(window).on('load', function() {
  $('.sub-page .head-line').addClass('show');
});

// --- リサイズが走った場合 ----------------------------------------
$(window).on('resize', function() {});

// --- DOM生成後 -----------------------------------------------
$(function() {
  console.log('script.js');

  var observer = lozad('.lozad', {
    loaded: function(el) {
      // Custom implementation on a loaded element
      // console.log(el);
      el.classList.add('loaded');
    }
  });
  // lazy loads elements with default selector as '.lozad'
  observer.observe({});

  if ($('#topPage').length) {
    topFunc();
  } else {
    subFunc();
  }
  if($('#formPage1').length){
    formCheck();
    document.getElementById('forClient').addEventListener('click', function(e){
      e.preventDefault();
      document.getElementById('modalWrap').classList.add('show');
    }, false);
    document.getElementById('closeModal').addEventListener('click', function(){
      document.getElementById('modalWrap').classList.remove('show');
    }, false);

  }
  if($('#formPage2').length){
    questionnaireCheck();
  }

  
  // MENU
  $('#menu-trigger').click(function() {
    if ($('body').hasClass('menu-show')) {
      $('body').removeClass('menu-show');
    } else {
      $('body').addClass('menu-show');
    }
  });

  // PC or SPサンプル
  if (mq('sp')) {
    console.log('Mobile');
    // SMOOTH SCROOL FOR CLASS NAME
    $('a[href^="#"]').click(function(){
      var speed = 500;
      var href= $(this).attr("href");
      var target = $(href == "#" || href == "" ? 'html' : href);
      var position = target.offset().top - 100;
      $("html, body").animate({scrollTop:position}, speed, "swing");
      return false;
    });
    $('.gnav ul li a').each(function(){
      $(this).click(function(){
        $('body').removeClass('menu-show');
      });
    });
  } else {
    console.log('Desktop');
    // SMOOTH SCROOL FOR CLASS NAME
    $('a[href^="#"]').click(function(){
      var speed = 500;
      var href= $(this).attr("href");
      var target = $(href == "#" || href == "" ? 'html' : href);
      var position = target.offset().top;
      $("html, body").animate({scrollTop:position}, speed, "swing");
      return false;
    });
  }
});

// SCROLL AND TOCH START ANIMATION
// $(window).on('touchstart scroll', function() {
//   var myClass = $(
//     '.head-line, #topPage .bussiness-list li, #topPage .vision-list li, #topPage .about-list li'
//   );
//   var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
//   var windowHeight = document.documentElement.clientHeight;
//   for (var i = 0; i < myClass.length; i++) {
//     var rect = myClass[i].getBoundingClientRect();
//     var imgPos = rect.top + scrollTop + 300;

//     if (scrollTop > imgPos - windowHeight) {
//       myClass[i].classList.add('show');
//     } else if (scrollTop < imgPos + windowHeight) {
//       // myClass[i].classList.remove('show');
//     }
//   }
//   if (scrollTop > 150) {
//     document.getElementById('headerWrap').classList.add('fixed');
//     document.getElementById('pageTop').classList.add('show');
//   } else if (scrollTop < 150) {
//     document.getElementById('headerWrap').classList.remove('fixed');
//     document.getElementById('pageTop').classList.remove('show');
//   }
// });
